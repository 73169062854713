.verification-status-failed {
    @include flexContainer;
    @include flexHVCenter;

    .verification-status-overlay {
        display: block;
        box-sizing: border-box;
        @include overlayCard;
        width: 560px;

        .icon {
            justify-content: center;
            img {
                height: 80px;
                width: 80px;
            }
        }

        .title {
            text-align: center;
            padding: 40px 0px;
        }

        .description {
            justify-content: center;
        }

        .sticky_bottom {
            margin-top: 40px;
        }

        .instructions {
            margin-bottom: 40px;
        }

        h1 {
            @include regularCenter;
        }

        .row {
            p {
                max-width: 430px;
                margin-bottom: 40px;
            }
        }

        .pure-control-group {
            input {
                width: 428px;
                height: 56px;
                margin-bottom: 24px;
            }
        }

        .verify-address-error-layout {
            text-align: center;
            margin-top: 5px;
            margin-bottom: 15px;
            height: 24px;

            .error {
                font-size: 16px;
                color: red;
            }
        }

        .pure-controls {
            button {
                background-color: $accentColor;
                color: $negativeColor;
                border-radius: $size24;
                border: 0;
                &:hover,
                &:focus,
                &:active {
                    background-color: $accentColorHover;
                }
                width: 272px;
                padding: 10px;
            }
            display: flex;
            justify-content: center;
        }

        .sticky_bottom {
            .pure-controls {
                button {
                    width: 320px;
                    font-size: 20px;
                    font-weight: 30px;
                }
                #back-to-sign-in {
                    font-size: 20px;
                    font-weight: 30px;
                }
            }
        }
    }
}
