@include desktop {
}
@include laptop {
}
@include tablet {
}

@include phone {
    .device-is-not-activated {
        padding: 0px 10px 0px 10px;

        .device-is-not-activated-overlay {
            width: 100%;
            padding: 40px 32px;

            .email {
                p {
                    font-size: 18px;
                }
            }

            .icon {
                img {
                    height: 60px;
                    width: 60px;
                }
            }

            .title {
                h1 {
                    font-size: 28px;
                }
                padding: 16px 0;
            }
        }
    }
}
