input {
    border: 0.5px solid #ccc;
    border-radius: 4px;
    padding: 7px;
}

.error_input {
    border: 0.5px solid $errorColor;
}

.input-wrapper {
    width: auto;
    height: auto;
    position: relative;
}

.input-wrapper {
    .label-error {
        color: $errorColor;
    }

    label {
        color: $textPlaceholderColor;
        padding: 14px;
        position: absolute;
        top: 2px;
        left: 0;
        -moz-transition: 0.3s ease all;
        -webkit-transition: 0.3s ease all;
        transition: 0.3s ease all;
        pointer-events: none;
        border-radius: 30px;
    }
}

input:focus + label,
input:valid + label,
input:-webkit-autofill + label {
    font-size: 12px;
    color: $accentColorFocus;
    top: -9px;
    left: 14px;
    background: $negativeColor;
    padding: 0px 5px 0px 5px;
}

input:focus + .label-error,
input:valid + .label-error,
input:-webkit-autofill + .label-error {
    color: $errorColor;
}
