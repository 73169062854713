@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../../public/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.eot');
    src:
        url('../../../public/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.eot?#iefix')
            format('embedded-opentype'),
        url('../../../public/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.woff2')
            format('woff2'),
        url('../../../public/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.woff')
            format('woff'),
        url('../../../public/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.ttf')
            format('truetype'),
        url('../../../public/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular')
            format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro Demi';
    src: url('../../../public/fonts/AvenirNextLTPro/AvenirNextLTPro-Demi.eot');
    src:
        url('../../../public/fonts/AvenirNextLTPro/AvenirNextLTPro-Demi.eot?#iefix')
            format('embedded-opentype'),
        url('../../../public/fonts/AvenirNextLTPro/AvenirNextLTPro-Demi.woff2')
            format('woff2'),
        url('../../../public/fonts/AvenirNextLTPro/AvenirNextLTPro-Demi.woff')
            format('woff'),
        url('../../../public/fonts/AvenirNextLTPro/AvenirNextLTPro-Demi.ttf')
            format('truetype'),
        url('../../../public/fonts/AvenirNextLTPro/AvenirNextLTPro-Demi.svg#AvenirNextLTPro-Demi')
            format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
