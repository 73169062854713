.email-sent {
    @include flexContainer;
    @include flexHVCenter;

    .email-sent-overlay {
        display: block;
        box-sizing: border-box;
        @include overlayCard;
        width: 560px;

        h1 {
            @include regularCenter;
            margin-bottom: 40px;
        }

        p {
            max-width: 430px;
            margin-bottom: $breakHorizontal;
        }

        .pure-control-group {
            input {
                width: 428px;
                height: 56px;
                margin-bottom: 40px;
            }
        }
        #back-to-sign-in {
            display: flex;
            justify-content: center;
            width: 100%;
            a {
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
            }
        }
    }
}
