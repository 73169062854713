.primary-button {
    height: $size48;
    background-color: $accentColor;
    color: $negativeColor;
    padding: 0 $spacing48;
    border-radius: $size24;
    border: 0;
    -webkit-transition: background-color 0.1s ease-out;
    transition: background-color 0.1s ease-out;

    &:hover,
    &:focus,
    &:active {
        background-color: $accentColorHover;
    }
}

.ghost-button {
    height: $size48;
    background-color: $ghostColor;
    color: $negativeColor;
    padding: 0 $spacing48;
    border-radius: $size24;
    border: 0;
    -webkit-transition: background-color 0.1s ease-out;
    transition: background-color 0.1s ease-out;

    &:hover,
    &:focus,
    &:active {
        background-color: $ghostColorHover;
    }
}
