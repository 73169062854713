@include desktop {
    .login-okta {
        .login-okta-overlay {
            width: 560px;
            padding: 64px;
        }
    }
}

@include laptop {
    .login-okta {
        .login-okta-overlay {
            width: 560px;
            padding: 64px;
        }
    }
}

@include tablet {
    .login-okta {
        .login-okta-overlay {
            width: 560px;
            padding: 64px;
        }
    }
}

@include phone {
    .login-okta {
        padding: 0px 10px 0px 10px;
        .login-okta-overlay {
            width: 100%;
            padding: 40px 32px;

            .pure-controls {
                button {
                    width: 260px;
                    height: 48px;
                }
            }

            .logo {
                margin-bottom: 24px;
            }

            h1 {
                margin-bottom: 24px;
                font-size: 24px;
            }

            .title {
                margin-bottom: 24px;
            }
        }
    }
}
