html,
body {
    width: 100%;
    height: 100%;
    background-color: #000000;
    @include regularText;
}

.not-displayed {
    display: none;
}

@mixin displayFlex {
    display: flex;
}

@mixin flexContainer {
    @include displayFlex;
    width: 100%;
    min-height: 100%;
}

@mixin flexHVCenter {
    align-items: center;
    justify-content: center;
}

.flex-container {
    @include flexContainer;
}

.flex-hv-Center {
    @include flexHVCenter;
}

@mixin regularCenter {
    margin: 0 auto;
}

@mixin overlayCard {
    background: rgba($backgroundColor, 0.96);
    border: 1px solid $overlayBorderColor;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.18);
    border-radius: 48px;
    padding: 64px;
}

.row {
    margin-left: 0;
    margin-right: 0;

    .col,
    .col-9,
    .col-12 {
        padding: 0;
    }

    &.reverse {
        flex-direction: row-reverse;
    }
}

.wrapper {
    @include desktop {
        background: url('../../../public//images/misc/background-desktop.png');
        background-position: bottom center;
        background-size: cover;
    }
    @include laptop {
        background: url('../../../public//images/misc/background-desktop.png');
        background-position: bottom center;
        background-size: cover;
    }
    @include tablet {
        background: url('../../../public//images/misc/background-tablet.png');
        background-position: bottom center;
        background-size: cover;
    }
    @include phone {
        background: url('../../../public//images/misc/background-mobile.png');
        background-position: bottom center;
        background-size: cover;
    }

    @include flexContainer;
    @include flexHVCenter;
}
