.forgot-password {
    @include flexContainer;
    @include flexHVCenter;

    .forgot-password-overlay {
        display: block;
        box-sizing: border-box;
        @include overlayCard;
        width: 560px;

        h1 {
            @include regularCenter;
            margin-bottom: 40px;
            text-align: center;
        }

        p {
            max-width: 430px;
            margin-bottom: 40px;
        }

        .pure-control-group {
            input {
                width: 428px;
                height: 56px;
            }
        }

        .description-requirements {
            ul {
                padding-left: 15px;
            }
        }

        .new-password-invalid-layout,
        .confirm-new-password-invalid-layout {
            min-height: 24px;
        }

        .pure-controls {
            button {
                background-color: $accentColor;
                color: $negativeColor;
                border-radius: $size24;
                border: 0;
                &:hover,
                &:focus,
                &:active {
                    background-color: $accentColorHover;
                }
                width: 342px;
                height: 56px;
                margin-top: 16px;

                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
            }

            p.back-sign-in {
                margin-bottom: 0px;
            }

            a {
                margin-top: 20px;
            }

            display: flex;
            justify-content: center;
        }

        .email-invalid-layout {
            height: 24px;
        }

        .wide-layout {
            margin-bottom: 20px;
        }

        .error {
            font-size: 12px;
            color: red;
        }
    }
}
