.device-is-activated {
    @include flexContainer;
    @include flexHVCenter;

    .device-is-activated-overlay {
        display: block;
        box-sizing: border-box;
        @include overlayCard;
        width: 560px;

        .email {
            justify-content: center;

            p {
                font-size: 28px;
            }
        }

        .icon {
            justify-content: center;
            img {
                height: 80px;
                width: 80px;
            }
        }

        .title {
            text-align: center;
            padding: 40px 0;
        }

        .description {
            p {
                margin-bottom: 0px;
            }
        }

        h1 {
            @include regularCenter;
        }
    }
}
