$radiusRegular: 4px;

$spacing8: 8px;
$spacing16: 16px;
$spacing24: 24px;
$spacing32: 32px;
$spacing48: 48px;
$spacing64: 64px;
$spacing96: 96px;

$size8: 8px;
$size12: 12px;
$size16: 16px;
$size24: 24px;
$size32: 32px;
$size48: 48px;
$size64: 64px;
$size96: 96px;
$size128: 128px;

$bodySize: 16px;
$headerH1Size: 44px;
$headerH6Size: 20px;

$breakHorizontal: $spacing24;
