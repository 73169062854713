@mixin link {
    color: $accentColor;
    font-size: $bodySize;
    cursor: pointer;

    &:hover {
        color: $accentColorHover;
    }

    &:focus {
        color: $accentColorFocus;
    }

    &:active {
        color: $accentColorPressed;
    }
}

a {
    @include link;
}

a:not([href]):not([class]) {
    @include link;
}
