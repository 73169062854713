@include desktop {
    .verification-email-sent {
        .verification-email-sent-overlay {
            width: 560px;
        }
    }
}

@include laptop {
    .verification-email-sent {
        .verification-email-sent-overlay {
            width: 560px;
        }
    }
}

@include tablet {
    .verification-email-sent {
        .verification-email-sent-overlay {
            width: 560px;
        }
    }
}

@include phone {
    .verification-email-sent {
        padding: 0px 10px 0px 10px;

        .verification-email-sent-overlay {
            position: relative;
            width: 100%;
            padding: 40px 32px;

            h1 {
                font-size: 24px;
                margin-bottom: 16px;
            }

            P {
                padding-bottom: 32px;
            }
        }
    }
}
