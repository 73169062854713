@mixin confirmationIssueState {
    padding: $spacing96;

    .content {
        @include regularCenter;
        max-width: 740px;

        h1 {
            @include regularCenter;
            text-align: center;
            margin-bottom: $spacing64;
        }

        img {
            @include regularCenter;
            width: $size128;
            height: $size128;
            margin-bottom: $spacing48;
        }

        svg {
            @include regularCenter;
            width: $size128;
            height: $size128;
            margin-bottom: $spacing48;
        }

        span {
            @include regularCenter;
            text-align: center;
        }
    }
}

.details {
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr;
    background: #fff;

    .header {
        display: flex;
        align-items: center;
        height: 80px;
        background-color: $primaryColor;
        right: $spacing16;

        .row {
            width: 100%;
            margin: 0 $spacing32;
        }
    }

    .file-details {
        padding: $spacing64 $spacing96;

        h1 {
            margin-bottom: $spacing48;
        }

        .title {
            font-weight: bold;
        }

        img {
            width: 160px;
            height: 160px;
            margin-bottom: $spacing48;
        }

        h6 {
            margin-bottom: 10px;
        }

        .secondary-text {
            width: 160px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        button {
            margin-top: $spacing64;
            height: auto;
            padding: 10px 20px;
        }

        .separation-mid {
            margin-bottom: $spacing32;
        }

        .separation-small {
            margin-bottom: $spacing8;
        }

        .phrtf_label {
            word-break: break-all;
        }
    }

    .issue {
        @include confirmationIssueState();
    }

    .confirmation {
        @include confirmationIssueState();
    }
}
