.verification-email-sent {
    @include flexContainer;
    @include flexHVCenter;

    .verification-email-sent-overlay {
        display: block;
        box-sizing: border-box;
        @include overlayCard;

        h1 {
            @include regularCenter;
            margin-bottom: 40px;
            text-align: center;
        }

        p {
            max-width: 430px;
            padding-bottom: 40px;
            margin: 0;
        }

        .pure-control-group {
            input {
                width: 428px;
                height: 56px;
                margin-bottom: 40px;
            }
        }

        .pure-controls {
            a {
                color: $accentColor;
                font-size: $headerH6Size;
                text-align: center;
            }
            display: flex;
            justify-content: center;
        }
    }
}
