.activate-your-device {
    @include flexContainer;
    @include flexHVCenter;

    .activate-your-device-overlay {
        display: block;
        box-sizing: border-box;
        @include overlayCard;

        h1 {
            @include regularCenter;
            margin-bottom: 40px;
        }

        .row {
            p {
                max-width: 430px;
                margin-bottom: 40px;
            }
        }

        .pure-control-group {
            input {
                width: 428px;
                height: 56px;
            }
        }
        .activate-your-device-error-layout {
            text-align: left;
            margin-bottom: 20px;
            height: 24px;

            .error {
                font-size: 16px;
                color: red;
            }
        }

        .device-code-error {
            border-color: red;
        }

        .pure-controls {
            margin-top: 40px;

            button {
                background-color: $accentColor;
                color: $negativeColor;
                border-radius: $size24;
                border: 0;
                &:hover,
                &:focus,
                &:active {
                    background-color: $accentColorHover;
                }
                width: 320px;
                height: 48px;
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
            }
            display: flex;
            justify-content: center;
        }
    }
}
