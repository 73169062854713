.create-new-password {
    @include flexContainer;
    @include flexHVCenter;

    .create-new-password-overlay {
        display: block;
        box-sizing: border-box;
        @include overlayCard;
        width: 560px;

        h1 {
            @include regularCenter;
            margin-bottom: 40px;
        }

        p {
            max-width: 430px;
            margin-bottom: 16px;
        }

        .pure-control-group {
            input {
                width: 428px;
                height: 56px;
            }
        }

        .description-requirements {
            margin-bottom: 40px;

            ul {
                margin-bottom: 0px;
                padding-left: 15px;
                list-style: none;
            }
            li::before {
                content: '\2022';
                color: #d9d9d9;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }
        }

        .new-password-invalid-layout {
            margin-bottom: 16px;
        }

        .pure-controls {
            button {
                background-color: $accentColor;
                color: $negativeColor;
                border-radius: $size24;
                border: 0;
                &:hover,
                &:focus,
                &:active {
                    background-color: $accentColorHover;
                }
                &:disabled {
                    background-color: $accentColorDisabled;
                }
                width: 342px;
                height: 56px;

                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
            }
            display: flex;
            justify-content: center;
        }

        .error {
            font-size: 12px;
            color: red;
        }

        .sticky_bottom {
            padding-top: 40px;

            a {
                margin-top: 16px;
            }
        }
    }
}
