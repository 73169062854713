@include desktop {
}
@include laptop {
}
@include tablet {
}

@include phone {
    .email-sent {
        padding: 0px 10px 0px 10px;

        .email-sent-overlay {
            width: 100%;
            padding: 40px 32px;

            .title {
                h1 {
                    font-size: 28px;
                    margin-bottom: 16px;
                }
            }
        }
    }
}
