@include desktop {
}
@include laptop {
}
@include tablet {
}

@include phone {
    .create-new-password {
        padding: 0px 10px 0px 10px;

        .create-new-password-overlay {
            width: 100%;
            padding: 30px 22px;

            .title {
                h1 {
                    font-size: 28px;
                    margin-bottom: 16px;
                }
            }

            .pure-control-group {
                input {
                    width: 100%;
                }
            }

            .pure-controls {
                button {
                    width: 278px;
                    height: 48px;
                }
            }

            .sticky_bottom {
                padding-top: 10px;
            }
        }
    }
}
