.lds-dual-ring {
    width: $size64;
    height: $size64;
    opacity: 0.4;
}
.lds-dual-ring:after {
    content: ' ';
    display: block;
    width: $size64;
    height: $size64;
    margin: $size8;
    border-radius: 50%;
    border: 4px solid $negativeColor;
    border-color: $negativeColor transparent transparent transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
