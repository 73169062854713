@include desktop {
}

@include laptop {
}

@include tablet {
}

@include phone {
    .verification-status-failed {
        padding: 0px 10px 0px 10px;

        .verification-status-overlay {
            position: relative;
            width: 100%;
            padding: 40px 32px;

            .icon {
                img {
                    height: 60px;
                    width: 60px;
                }
            }

            .title {
                padding: 16px 0px;
                h1 {
                    font-size: 28px;
                }
            }

            .instructions {
                margin-bottom: 32px;
            }

            .sticky_bottom {
                margin-top: 32px;
            }
        }
    }
}
