@import '../tokens/colors';
@import '../tokens/dimensions';
@import '../fonts/avenir_next_lt_pro';

@mixin regularText {
    color: $primaryColor;
    font-size: $bodySize;
    font-family: 'Avenir Next LT Pro', 'Arial Narrow', 'sans-serif';
}

p {
    @include regularText;
}

h1 {
    color: $primaryColor;
    font-family: 'Avenir Next LT Pro Demi', 'Arial Narrow', 'sans-serif';
    font-size: $headerH1Size;
    max-width: 780px;
}

h6 {
    color: $primaryColor;
    font-family: 'Avenir Next LT Pro Demi', 'Arial Narrow', 'sans-serif';
    font-size: $headerH6Size;
}

.label {
    margin-right: 8px;
}

.primary-text {
    @include regularText;
}

.secondary-text {
    color: $secondaryColor;
    font-size: $bodySize;
    font-family: 'Avenir Next LT Pro', 'Arial Narrow', 'sans-serif';
}
.version-text {
    color: $secondaryColor;
    font-size: $size12;
    margin-top: $breakHorizontal;
}
