@include desktop {
}
@include laptop {
}
@include tablet {
}

@include phone {
    .activate-your-device {
        padding: 0px 10px 0px 10px;

        .activate-your-device-overlay {
            position: relative;
            width: 100%;
            padding: 40px 32px;

            .title {
                h1 {
                    font-size: 28px;
                    margin-bottom: 16px;
                }
            }

            .row {
                p {
                    margin-bottom: 16px;
                }
            }

            .pure-control-group {
                input {
                    width: 100%;
                }
            }

            .pure-controls {
                margin-top: 32px;

                button {
                    width: 278px;
                    height: 48px;
                }
            }
        }
    }
}
