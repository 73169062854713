.verify-your-address {
    @include flexContainer;
    @include flexHVCenter;

    .verify-your-address-overlay {
        display: block;
        box-sizing: border-box;
        @include overlayCard;

        h1 {
            @include regularCenter;
            margin-bottom: 40px;
            text-align: center;
        }

        p {
            max-width: 430px;
            margin-bottom: 40px;
        }

        .pure-control-group {
            input {
                width: 428px;
                height: 56px;
                margin-bottom: 24px;
            }
        }
        .verify-address-error-layout {
            text-align: left;
            margin-bottom: 40px;
            height: 24px;

            .error {
                font-size: 16px;
                color: red;
            }
        }

        .pure-controls {
            button {
                background-color: $accentColor;
                color: $negativeColor;
                border-radius: $size24;
                border: 0;
                &:hover,
                &:focus,
                &:active {
                    background-color: $accentColorHover;
                }
                width: 272px;
                height: 56px;
            }
            display: flex;
            justify-content: center;
        }

        p.last-paragraph {
            margin-bottom: 0px;
            padding-bottom: 0px;
        }
    }
}
