@include desktop {
    .verify-your-address {
        .verify-your-address-overlay {
            width: 560px;
        }
    }
}

@include laptop {
    .verify-your-address {
        .verify-your-address-overlay {
            width: 560px;
        }
    }
}

@include tablet {
    .verify-your-address {
        .verify-your-address-overlay {
            width: 560px;
        }
    }
}

@include phone {
    .verify-your-address {
        padding: 0px 10px 0px 10px;

        .verify-your-address-overlay {
            position: relative;
            width: 100%;
            padding: 64px 20px 64px 20px;

            p.last-paragraph {
                margin-bottom: 0px;
                padding-bottom: 0px;
            }

            h1 {
                font-size: 28px;
                margin-bottom: 16px;
            }

            p {
                margin-bottom: 32px;
            }

            .verify-address-error-layout {
                margin-bottom: 32px;
            }
        }
    }
}
