$breakpoint_sm: 360px; // phone
$breakpoint_md: 560px; // tablet
$breakpoint_l: 1024px; // laptop
$breakpoint_xl: 1200px; // desktop

@mixin phone() {
    @media (max-width: ($breakpoint_md - 0.2px)) and (min-width: $breakpoint_sm) {
        @content;
    }
}

@mixin tablet() {
    @media (max-width: ($breakpoint_l - 0.2px)) and (min-width: $breakpoint_md) {
        @content;
    }
}

@mixin laptop() {
    @media (max-width: ($breakpoint_xl - 0.2px)) and (min-width: $breakpoint_l) {
        @content;
    }
}

@mixin desktop() {
    @media (min-width: $breakpoint_xl) {
        @content;
    }
}
