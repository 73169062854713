@include desktop {
}

@include laptop {
}

@include tablet {
}

@include phone {
    .details {
        .file-details {
            .title {
                font-size: 28px;
            }

            img {
                height: 120px;
                width: 120px;
            }

            .file-data {
                #user_email {
                    -ms-word-break: break-all;
                    word-break: break-all;
                    word-break: break-word;
                }
            }
        }

        .header {
            .ghost-button {
                padding: 0px 10px;
            }
        }

        .issue {
            padding: 50px 32px;

            #error_message {
                -ms-word-break: break-all;
                word-break: break-all;
                word-break: break-word;
            }
        }

        .file-details {
            padding: 46px 30px;
        }

        .confirmation {
            padding: 96px 15px;
            max-width: 100%;

            h1 {
                font-size: 32px;
            }

            .download-manually {
                span {
                    max-width: 300px;

                    -ms-word-break: break-all;
                    word-break: break-all;
                    word-break: break-word;
                }
            }
        }
    }
}
