.login-okta {
    @include flexContainer;
    @include flexHVCenter;

    .login-okta-overlay {
        display: block;
        box-sizing: border-box;
        @include overlayCard;

        h1 {
            @include regularCenter;
            font-size: 28px;
            margin-bottom: 40px;
        }

        .logo {
            width: 190px;
            height: 48px;
            @include regularCenter;
            margin-bottom: 40px;
        }

        .title {
            margin-bottom: 40px;
        }

        .short-title {
            justify-content: center;
        }

        #password-control-group {
            margin-top: 24px;
        }

        .pure-control-group {
            input {
                width: 100%;
                height: 56px;
            }
        }

        .login-error-message {
            margin-bottom: 15px;
            height: 24px;

            p {
                color: #dc3545;
                text-align: center;
            }
        }

        .pure-controls {
            button {
                background-color: $accentColor;
                color: $negativeColor;
                border-radius: $size24;
                border: 0;
                &:hover,
                &:focus,
                &:active {
                    background-color: $accentColorHover;
                }
                width: 320px;
                height: 48px;
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
            }
            img {
                max-width: 50%;
                padding-left: 15px;
            }
            display: flex;
            justify-content: center;
            padding: 6px;
            text-align: center;
        }

        .recover-password {
            display: flex;
            justify-content: right;
            -webkit-justify-content: flex-end;
            align-items: flex-end;
            -webkit-align-items: flex-end;
            margin: 5px 0px;
            height: 24px;
        }

        .email-invalid-layout {
            height: 24px;
        }

        .password-invalid-layout {
            height: 24px;
        }

        .signin-invalid-layout {
            text-align: left;
            min-height: 50px;
            span {
                font-size: 16px;
                text-align: center;
            }
        }

        .error {
            font-size: 12px;
            color: red;
        }

        #unable-signin {
            font-size: 14px;
            text-align: center;
        }

        #get-phrtf-file-block {
            padding-top: 20px;
        }
    }
}
