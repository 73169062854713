@include desktop {
}
@include laptop {
}
@include tablet {
}

@include phone {
    .password-updated {
        padding: 0px 10px 0px 10px;

        .password-updated-overlay {
            position: relative;
            width: 100%;
            padding: 40px 32px;

            .icon {
                img {
                    height: 60px;
                    width: 60px;
                }
            }

            .title {
                h1 {
                    font-size: 28px;
                    margin-bottom: 16px;
                }
            }

            .description {
                justify-content: left;
                padding-bottom: 0px;
            }

            .sticky_bottom {
                position: absolute;
                bottom: 20px;
                left: 50%;
                transform: translate(-50%, 0);

                .pure-controls {
                    button {
                        width: 278px;
                    }
                }
            }
        }
    }
}
