@include desktop {
}
@include laptop {
}
@include tablet {
}

@include phone {
    .forgot-password {
        padding: 0px 10px;

        .forgot-password-overlay {
            width: 100%;
            padding: 40px 32px;

            .title {
                h1 {
                    font-size: 28px;
                    margin-bottom: 16px;
                }
            }

            p {
                max-width: 430px;
                margin-bottom: 16px;
            }

            .pure-control-group {
                input {
                    width: 100%;
                }
            }

            .pure-controls {
                button {
                    width: 278px;
                    height: auto;
                    padding: 8px 0px;
                }
            }
        }
    }
}
