$accentColor: #3b48fe;
$primaryColor: #000000;
$secondaryColor: #61616a;
$negativeColor: #ffffff;
$errorColor: #ff4d4d;
$transparentColor: rgba(255, 255, 255, 0);

$backgroundColor: #ffffff;

$overlayBackgroundColor: #f0f0f0;
$overlayBorderColor: #dddddd;

$loginButtonBackgroundColor: #ffffff;
$loginButtonBorderColor: #dddddd;

$textPlaceholderColor: #afbdcf;

$accentColorHover: #3b67ff;
$accentColorPressed: #3043ef;
$accentColorFocus: #467cff;
$accentColorDisabled: #3b48fe80;

$ghostColor: $transparentColor;
$ghostColorHover: rgba(255, 255, 255, 0.1);
$ghostColorPressed: rgba(255, 255, 255, 0.05);
$ghostColorFocus: rgba(255, 255, 255, 0.1);
